import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  watch,
  computed
} from 'vue'
import {
  senderDataUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { async } from 'rxjs'
import moment from 'moment'

const modules = 'deliveryItem'

export default {
  name: 'FormSenderEtc',
  setup() {
    const store = useStore()
    const customerOption = ref([])
    const companyAdressOption = ref([])
    const app = getCurrentInstance()
    const addressSenderRef = ref()
    const addressReceiverRef = ref()
    const paymentTypeId = ref()
    const scheduleOption = ref([])
    const isDooring = ref(false)
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const dataForm = reactive({
      customerId: computed(() => store.state[modules].customerId),
      // pickupScheduleId: computed(() => store.state[modules].pickupScheduleId),
      sendingAddressId: computed(() => store.state[modules].adressCompanyId),
      pickupScheduleId: computed({
        get: () => store.state[modules].dataSender.pickupScheduleId,
        set: (val) => {
          // console.log('pickup', val)
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: val
          })
          // console.log('state', store.state[modules].dataSender)
        }
      }),
      addressSender: computed({
        get: () => store.state[modules].dataSender.addressSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: val,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      // telpSender: computed(() => store.state[modules].dataSender.telpSender),
      telpSender: computed({
        get: () => store.state[modules].dataSender.telpSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: val,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      // nameSender: computed(() => store.state[modules].dataSender.nameSender),
      nameSender: computed({
        get: () => store.state[modules].dataSender.nameSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: val,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      nameReceiver: computed({
        get: () => store.state[modules].dataReceiver.nameReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: val,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver
          })
        }
      }),
      telpReceiver: computed({
        get: () => store.state[modules].dataReceiver.telpReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: val,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      addressReceiver: computed({
        get: () => store.state[modules].dataReceiver.addressReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: val,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      notesReceiver: computed({
        get: () => store.state[modules].dataReceiver.notesReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: val
          })
        }
      })
    }) as any
    const submitted = ref(false)

    const rules = {
      nameSender: {
        required: helpers.withMessage('Pengirim harus diisi', required)
      },
      telpSender: {
        required: helpers.withMessage('No. Telp Pengirim harus diisi', required)
      },
      addressSender: {
        required: helpers.withMessage('Alamat Pengirim harus diisi', required)
      },
      nameReceiver: {
        required: helpers.withMessage('Penerima harus diisi', required)
      },
      addressReceiver: {
        required: helpers.withMessage('Alamat Penerima harus diisi', required)
      },
      telpReceiver: {
        required: helpers.withMessage('No. Telp Penerima harus diisi', required)
      },
      notesReceiver: {
        required: helpers.withMessage('Keterangan Alamat harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    onMounted(() => {
      // console.log('ini store', store)
      // eslint-disable-next-line no-use-before-define
      getDefaultPaymentType()
      // eslint-disable-next-line no-use-before-define
      getOptionSchedule()
      // eslint-disable-next-line no-use-before-define
      checkRoute()
    })

    const prosesSubmit = async () => {
      console.log('dataform', dataForm)
      console.log('submit state', store.state[modules])
      store.dispatch('showLoading')
      const data = {
        AgenAsalId: store.getters['appActiveUser/getAgent'].id,
        AgenTujuanId: store.state[modules].toAgentId,
        TipePengiriman: store.state[modules].typeDelivery,
        NamaPengirim: dataForm.nameSender,
        AlamatPengirim: dataForm.addressSender,
        HpPengirim: dataForm.telpSender,
        NamaPenerima: dataForm.nameReceiver,
        AlamatPenerima: dataForm.addressReceiver,
        HpPenerima: dataForm.telpReceiver,
        PelangganId: null,
        Username: store.state[modules].dataSender?.usernameSender ?? null,
        KeteranganPenerima: dataForm.notesReceiver,
        TransJadwalHdId: store.state[modules].scheduleId,
        JenisPembayaranId: paymentTypeId.value,
        TransJadwalKurirId: dataForm.pickupScheduleId === 0 ? null : dataForm.pickupScheduleId
        // TransJadwalKurirId: null
      }
      const result = await senderDataUseCase.newSubmitData(data, store.state[modules].dataAfterPost !== null ? store.state[modules].dataAfterPost.Id : null)
      if (!result.error) {
        // console.log('data post', result)
        // await store.dispatch(`${modules}/setDataAfterPost`, result.result)
        store.dispatch('hideLoading')
        store.dispatch(`${modules}/setCurrentIndex`, 2)
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: result.message,
          group: 'bc',
          life: 3000
        })
      }
    }

    const getDefaultPaymentType = async () => {
      const response = await senderDataUseCase.getDefaultPaymentType()
      const data = response.result
      if (data.length > 0) {
        paymentTypeId.value = data[0].Id
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      prosesSubmit()
    }

    const submitBack = () => {
      store.dispatch(`${modules}/setCurrentIndex`, 0)
    }

    const setResultAddressSender = (result: any) => {
      dataForm.addressSender = result
    }

    const setResultAddressReceiver = (result: any) => {
      dataForm.addressReceiver = result
    }
    // watch([dataForm.addressSender], ([newValues], [prevValues]) => {
    //   console.log(newValues, prevValues)
    // })

    const getOptionSchedule = async () => {
      moment.locale('id')
      const response = await senderDataUseCase.getOptionSchedule()
      // console.log('res', response)
      scheduleOption.value = response.result.map((x: any) => ({
        label: `${moment(x.WaktuMulaiPenjemputan).format('D MMMM YYYY')} ${moment(x.WaktuMulaiPenjemputan).format('HH:mm')} WIB - ${moment(x.WaktuAkhirPenjemputan).format('HH:mm')} WIB`,
        value: x.Id
      }))
    }

    const checkRoute = async () => {
      const currType = store.state[modules].typeDelivery
      if (currType === 'DA' || currType === 'DD') {
        isDooring.value = true
      }
    }

    return {
      customerOption,
      dataForm,
      companyAdressOption,
      store,
      submitData,
      submitBack,
      setResultAddressSender,
      setResultAddressReceiver,
      addressSenderRef,
      addressReceiverRef,
      v$,
      rules,
      submitted,
      app,
      scheduleOption,
      getOptionSchedule,
      isDooring
    }
  }
}
