import {
  ref, onMounted, computed, getCurrentInstance, reactive
} from 'vue'
import {
  transPengirimanDetailUseCase,
  senderDataUseCase,
  getAllItemsKategoriTipeAgent
} from '@/domain/usecase'
import { useStore } from 'vuex'
import stores from '@/store'
import label from '@/router/master/items/label'
import _ from 'lodash'

const modules = 'deliveryItem'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const weight = ref('') as any
    const volume = ref(0) as any
    const textWeight = ref('')
    const store = useStore()
    const listCategoryItems = computed(() => store.state[modules].listCategoryItems)
    const listItems = computed(() => store.state[modules].listItems)
    const listInsurance = ref([])
    const categoryItemsId = ref('')
    const insuranceId = ref(null)
    const totalSend = ref(1)
    const selectedCategoryItem = ref({}) as any
    const dialogCatatan = ref(false)
    const inputCatatan = ref('')
    const currentCatatanId = ref(null) as any
    const insuranceInput = ref([]) as any
    const addPriceInput = ref([])
    const grandPrice = ref(0)
    const dataItems = ref(listItems.value.map((item: any) => item) ?? []) as any
    const disableInsurance = ref(false)
    const AsuransiName = ref('') as any
    const prevCategoryItemsId = ref('')
    const itemsList = ref([]) as any
    const exceptionDeliveryType = ref(['AA'])
    const isMiisi = computed(() => store.state[modules].isMiisi)
    const isDraft = computed(() => store.state[modules].isDraft)
    const filterItemList = ref({
      id: store.getters['appActiveUser/getAgent']?.tipeAgenId,
      isDooring: true,
      pageNumber: 1
    })

    const dataDiskon = computed({
      get: () => store.state[modules].diskonPelanggan,
      set: (val) => {
        store.dispatch(`${modules}/setDiskonPelanggan`, val)
      }
    })

    const getAllData = async () => {
      if (exceptionDeliveryType.value.includes(store.state[modules].dataAfterPost.TipePengiriman)) {
        filterItemList.value.isDooring = false
      }
      const response = await getAllItemsKategoriTipeAgent(filterItemList.value)
      if (!response.error) {
        // console.log('response', response)
        const pageNumberItemType = response.result.PageNumber
        const pageTotalItemType = response.result.TotalPages
        // itemsList.value.concat(response.result.Data)
        itemsList.value = _.union(itemsList.value, response.result.Data)
        // console.log(itemsList.value)
        filterItemList.value.pageNumber = pageNumberItemType + 1
        // console.log(filterItemList.value.pageNumber <= pageTotalItemType)
        if (filterItemList.value.pageNumber <= pageTotalItemType) {
          // console.log('aaaa')
          getAllData()
        }
        // console.log('xxxx')
        store.dispatch(`${modules}/setListCategoryItems`, itemsList.value)
        // console.log('storeAfter', store.state)
      } else {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      }
    }

    const getGrandPrice = () => {
      grandPrice.value = 0
      listItems.value.forEach((item: any) => {
        grandPrice.value = grandPrice.value + Number(item.AsuransiValue) + Number(item.BiayaTambahan) + Number(item.Harga)
      })
    }

    const pushDataItems = (item: any) => {
      /* eslint-disable-next-line prefer-spread */
      dataItems.value.push.apply(dataItems.value, item)
      getGrandPrice()
    }

    const changeDataItems = (item: any) => {
      for (let index = 0; index < dataItems.value.length; index++) {
        if (item.Id === dataItems.value[index].Id) {
          dataItems.value[index] = item
          break
        }
      }
    }

    const deleteDataItems = (id: any) => {
      for (let index = 0; index < dataItems.value.length; index++) {
        if (id === dataItems.value[index].Id) {
          dataItems.value.splice(index, 1)
          break
        }
      }
    }

    const onChangeCategoryItem = (val: any) => {
      const categoryItem = listCategoryItems.value.filter((c: any) => c.Id === val)
      // eslint-disable-next-line prefer-destructuring
      selectedCategoryItem.value = categoryItem[0]
      textWeight.value = selectedCategoryItem.value.SatuanNama
      disableInsurance.value = !selectedCategoryItem.value.IsAsuransi
    }

    const proccessSubmitCategoryItem = async () => {
      const prevCategoryItemsIdTemp = prevCategoryItemsId.value
      store.dispatch('showLoading')
      // console.log('dataAfterPost', store.state[modules].dataAfterPost)
      const submitData = {
        // JadwalHdId: store.state[modules].scheduleId,
        KategoriBarangId: categoryItemsId.value,
        // AgenAsalId: store.state.appActiveUser.agent.AgenId,
        // AgenTujuanId: store.state[modules].toAgentId,
        PengirimanHdId: store.state[modules].dataAfterPost.Id,
        Berat: Number(weight.value),
        Volume: Number(volume.value),
        // TipePengiriman: store.state[modules].typeDelivery,
        // IsCompany: store.state[modules].dataAfterPost.CompanyId !== null,
        IsCompany: Object.keys(store.state[modules].dataAfterPost).indexOf('Username') !== -1 && store.state[modules].dataAfterPost.Username !== null,
        Jumlah: totalSend.value,
        AsuransiId: insuranceId.value,
      }

      if (store.state[modules].positionTabs === 2 && prevCategoryItemsId.value !== categoryItemsId.value && prevCategoryItemsId.value !== '' && store.state[modules].dataAfterPost.TipePengiriman.charAt(0).toLowerCase() === 'd') {
        $toast.add({
          severity: 'error', detail: 'Kategori barang harus sama', group: 'bc', life: 3000
        })
      } else {
        prevCategoryItemsId.value = submitData.KategoriBarangId
        const response = await transPengirimanDetailUseCase.addCustom(submitData)
        if (!response.error) {
          const { result } = response
          if (result[0].AsuransiId !== null) {
            AsuransiName.value = listInsurance.value.find((element: any) => element.value === result[0].AsuransiId)
            result[0].NamaAsuransi = AsuransiName.value.label ?? ''
          } else {
            result[0].NamaAsuransi = ''
          }
          result[0].TotalHarga = Number(result.AsuransiValue) + Number(result.BiayaTambahan) + Number(result.Harga)
          result[0].Keterangan = ''
          result[0].Volume = volume.value
          result[0].DiskonId = null
          result[0].DiskonValue = 0
          pushDataItems(result)
        } else {
          prevCategoryItemsId.value = prevCategoryItemsIdTemp
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 3000
          })
        }
      }
      store.dispatch('hideLoading')
    }

    const submitCategoryItem = () => {
      if (!categoryItemsId.value) {
        $toast.add({
          severity: 'error', detail: 'Kategori barang harus dipilih', group: 'bc', life: 3000
        })
      } else if (!weight.value) {
        $toast.add({
          severity: 'error', detail: 'Satuan barang harus diisi', group: 'bc', life: 3000
        })
      } else {
        proccessSubmitCategoryItem()
      }
    }

    const getAllInsurance = async () => {
      const response = await senderDataUseCase.getSelectDataInsurance('?$filter=IsActive eq true')
      listInsurance.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Nama
      }))
      // console.log('ansurance', response)
    }

    const submitData = async () => {
      store.dispatch('showLoading')
      if (listItems.value.length === 0) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', detail: 'Silahkan isi daftar barang terlebih dahulu', group: 'bc', life: 3000
        })
        return
      }
      const formData = listItems.value.map((item: any) => ({
        Id: item.Id,
        // KategoriBarangId: item.KategoriBarangId,
        BiayaTambahan: item.BiayaTambahan,
        // TotalHarga: Number(item.Harga) + Number(item.AsuransiValue) + Number(item.BiayaTambahan),
        // AsuransiValue: item.AsuransiValue,
        Keterangan: item.Keterangan,
        // AsuransiId: 0,
        // Volume: item.Volume,
        // HargaHdId: item.HargaHdId,
        // Jumlah: item.Berat,
        // IsCompany: store.state[modules].deliveryIsCompany,
        // PengirimanHdId: 10,
        // Berat: item.Berat,
      }))
      const response = await transPengirimanDetailUseCase.submitData(null, formData)
      if (!response.error) {
        // console.log('step3 res', response)
        store.dispatch(`${modules}/setDiskonPelanggan`, response.result.Data.Diskon)
        store.dispatch('hideLoading')
        store.dispatch(`${modules}/setCurrentIndex`, 3)
        // console.log('state step 3', store.state[modules])
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', detail: 'Gagal, merubah daftar barang, silahkan periksa koneksi internet anda dan coba kembali', group: 'bc', life: 3000
        })
      }
    }

    const submitBack = () => {
      if (!isMiisi.value && !isDraft.value) {
        dataItems.value = []
      }
      store.dispatch(`${modules}/setCurrentIndex`, 1)
    }

    const showCatatan = (id: any) => {
      currentCatatanId.value = id
      // eslint-disable-next-line prefer-destructuring
      inputCatatan.value = listItems.value.filter((item: any) => item.Id === id)[0].Keterangan
      dialogCatatan.value = !dialogCatatan.value
    }

    const submitCatatan = () => {
      const item = listItems.value.filter((items: any) => items.Id === currentCatatanId.value)[0]
      if (item) {
        item.Keterangan = inputCatatan.value
        changeDataItems(item)
        // store.dispatch(`${modules}/changeItemListItems`, item)
        dialogCatatan.value = !dialogCatatan.value
        inputCatatan.value = ''
        currentCatatanId.value = null
      } else {
        $toast.add({
          severity: 'error', detail: 'Gagal, menambahkan catatan, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
    }

    const deleteItems = (id: any, index: any) => {
      $confirm.require({
        header: 'Hapus Barang',
        message: 'Apakah anda yakin ingin menghapus barang yang dipilih?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await transPengirimanDetailUseCase.deleteData(id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus barang, silahkan coba kembali', group: 'bc', life: 3000
            })
          } else {
            await deleteDataItems(id)
            // store.dispatch(`${modules}/deleteItemListItems`, id)
            getGrandPrice()
            $toast.add({
              severity: 'success', detail: 'Behasil menghapus barang yang dipilih', group: 'bc', life: 3000
            })
          }
          store.dispatch('hideLoading')
        }
      })
    }

    const hideDialog = () => {
      dialogCatatan.value = false
    }

    onMounted(() => {
      getGrandPrice()
      getAllData()
      getAllInsurance()
      prevCategoryItemsId.value = ''
      // console.log('list', store.state)
    })

    return {
      textWeight,
      weight,
      volume,
      listCategoryItems,
      categoryItemsId,
      listItems,
      dataItems,
      dialogCatatan,
      inputCatatan,
      insuranceInput,
      addPriceInput,
      grandPrice,
      onChangeCategoryItem,
      submitCategoryItem,
      submitBack,
      submitData,
      deleteItems,
      showCatatan,
      submitCatatan,
      getGrandPrice,
      hideDialog,
      listInsurance,
      insuranceId,
      totalSend,
      disableInsurance,
      isMiisi
    }
  },
  watch: {
    dataItems: {
      handler(newValue: any) {
        stores.dispatch(`${modules}/setListItem`, newValue.map((item: any) => {
          item.TotalHarga = Number(item.AsuransiValue) + Number(item.BiayaTambahan) + Number(item.Harga)
          return item
        }))
        console.log('data items', newValue)
      },
      deep: true
    }
  }
}
